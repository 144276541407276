import { render, staticRenderFns } from "./RichText.vue?vue&type=template&id=3813d33f&scoped=true"
import script from "./RichText.vue?vue&type=script&lang=js"
export * from "./RichText.vue?vue&type=script&lang=js"
import style0 from "./RichText.vue?vue&type=style&index=0&id=3813d33f&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3813d33f",
  null
  
)

export default component.exports