import { render, staticRenderFns } from "./ChannelCardBack.vue?vue&type=template&id=568e0747&scoped=true"
import script from "./ChannelCardBack.vue?vue&type=script&lang=js"
export * from "./ChannelCardBack.vue?vue&type=script&lang=js"
import style0 from "./ChannelCardBack.vue?vue&type=style&index=0&id=568e0747&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "568e0747",
  null
  
)

export default component.exports