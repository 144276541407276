import { render, staticRenderFns } from "./QuizToolbar.vue?vue&type=template&id=13d502f3&scoped=true"
import script from "./QuizToolbar.vue?vue&type=script&lang=js"
export * from "./QuizToolbar.vue?vue&type=script&lang=js"
import style0 from "./QuizToolbar.vue?vue&type=style&index=0&id=13d502f3&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13d502f3",
  null
  
)

export default component.exports