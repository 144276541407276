import { render, staticRenderFns } from "./CompanyCard.vue?vue&type=template&id=6dc7c1d3&scoped=true"
import script from "./CompanyCard.vue?vue&type=script&lang=js"
export * from "./CompanyCard.vue?vue&type=script&lang=js"
import style0 from "./CompanyCard.vue?vue&type=style&index=0&id=6dc7c1d3&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc7c1d3",
  null
  
)

export default component.exports